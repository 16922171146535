.nuestrotrabajo-container {
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(255, 255, 255, 1) 0%,
    #a3c5f8 100%
  );
  padding-top: 100px;
  border-top: solid;
  padding-bottom: 80px;
}

.nuestrotrabajo-selector {
  width: 90%;
  height: 100%;
  margin: auto;
  padding: 20px 0 0 20px;
}

.nuestrotrabajo-text-selector {
  h1 {
    text-align: center;
    font-weight: 900;
    font-size: 40px;
    text-align: center;
    position: relative;
    padding-bottom: 15px;
  }

  h1:after {
    position: absolute;
    content: "";
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 150px;
    height: 3px;
    background: linear-gradient(
      257deg,
      rgba(40, 4, 240, 0.78) 0%,
      rgba(28, 132, 230, 0.78) 100%
    );
    margin: 0 auto;
  }
  p {
    // width: 100%;
    margin: 0 auto 60px auto;
    text-align: center;
    color: #707070;
  }
}

.nuestrotrabajo-card-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 25px;
  grid-row-gap: 15px;
}

.nuestrotrabajo-secondary {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-row-gap: 15px;
  grid-column-gap: 25px;
}

/*RESPONSIVE*/

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (max-width: 1200px) {} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (max-width: 992px) {
    .nuestrotrabajo-card-container {
        grid-template-columns: repeat(1, 1fr);
    }
} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (max-width: 768px) {} */

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .nuestrotrabajo-card-container {
    grid-template-columns: repeat(1, 1fr);
    /*grid-template-rows: repeat(5 , 1fr);*/
  }

  .nuestrotrabajo-secondary {
    /* grid-template-rows: repeat(1, 9%); */
    grid-template-columns: repeat(1, 1fr);
  }

  /* .nuestrotrabajo-secondarytwo {

        grid-template-rows: repeat(4, 1fr);
        grid-template-columns: repeat(1, 1fr);
    } */

  .nuestrotrabajo-information-text {
    padding: 0 30px 30px 60px;
    min-width: 315px;
  }

  .nuestrotrabajo-information-text h1 {
    font-size: 30px;
    padding: 50px 0;
  }

  .nuestrotrabajo-information-text p {
    width: 80%;
    font-size: 13px;
  }

  .nuestrotrabajo-text-selector h1 {
    font-size: 40px;
  }

  .nuestrotrabajo-text-selector p {
    width: 80%;
    // margin: 0 auto 60px auto;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 450px) {
  .nuestrotrabajo-information {
    padding: 40px 0 60px 0;
  }

  .nuestrotrabajo-information-text {
    padding: 30px 50px;
    min-width: 0;
    width: 100%;
  }

  .nuestrotrabajo-information-text h1 {
    font-size: 30px;
    padding: 50px 0;
  }

  .nuestrotrabajo-information-text p {
    width: 100%;
    font-size: 13px;
  }

  .nuestrotrabajo-text-selector h1 {
    font-size: 30px;
  }
}
