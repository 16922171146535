/* -----------------------------------------------------------------------------

# Base - Genral & Typography

----------------------------------------------------------------------------- */

html {
	font-size: 14px;
	font-weight: 400;
	line-height: 28px;
	height: 100%;
}

body {
	@include font-fam-sans;
	@include font-size-base;
	color: $text-color;
	overflow-x: hidden;
	img {
		max-width: 100%;
	}
}

a {
	cursor: pointer;
	text-decoration: none;
	@include anchor;
}


h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	@include font-fam-pop-b;
	margin: 0 0 $space 0;
	color: $text-color;

	&:last-child {
		margin: 0;
	}
}

h1, .h1 {
	font-size: 4rem;
	font-weight: 700;
	line-height: 4rem;

	@include mq-down("tablet") {
		font-size: 3rem;
		line-height: 3.25rem;
	}
	@include mq-down("mobile") {
		font-size: 2.5rem;
		line-height: 2.75rem;
	}
}

h2, .h2 {
	font-size: 3rem;
	font-weight: 700;
	line-height: 3.25rem;

	@include mq-down("tablet") {
		font-size: 2.5rem;
		line-height: 2.75rem;
	}
	@include mq-down("mobile") {
		font-size: 2rem;
		line-height: 2.25rem;
	}
}

h3, .h3 {
	font-size: 2rem;
	font-weight: 700;
	line-height: 2.25rem;

	@include mq-down("mobile") {
		font-size: 1.75rem;
		line-height: 2rem;
	}
}

h4, .h4 {
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1.75rem;

	@include mq-down("mobile") {
		font-size: 1.25rem;
		line-height: 1.5rem;
	}
}

h5, .h5 {
	font-size: 1.25rem;
	font-weight: 700;
	line-height: 1.5rem;
}

h6, .h6 {
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.5rem;
}

p {
	@include font-fam-sans;
	@include font-size-base;
	// color: $text-color;
	margin: 0 0 $space 0;

	&:last-child {
		margin: 0;
	}
}
