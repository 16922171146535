@import url('https://fonts.googleapis.com/css?family=Poppins:400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin&family=Redressed&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');
// @import url('https://fonts.googleapis.com/css2?family=Cabin&family=Redressed&family=Urbanist:wght@100&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Cabin&family=Redressed&family=Roboto:wght@500&family=Urbanist:wght@100&display=swap');

$primary-color: rgba(28, 132, 230, 0.78);
$text-color: #051922;
$text-color-light: #999999;
$white-color: #fff;
$black-color: #000;

// Fonts

$opensans-font: 'Open Sans', sans-serif;
$poppins-font: 'Poppins', sans-serif;

// Maximum widths

$max-width-s: 576px;
$max-width-m: 992px;
$max-width-l: 1200px;

// Spacing betwenn elements

$space: 1.25rem;