.Features-container {
    width: 100%;

    background-color: #fdfdfd;
    padding-bottom: 100px;
}

.Features-selector {
    width: 90%;
    height: 100%;
    margin: auto;
    /* padding: 20px 0 0 0; */
}

.Features-information {
    display: flex;
    justify-content: space-between;
    /*padding: 30px 0;*/
    padding: 40px 0 120px 0;
    background-size: cover;
    /* background-image: url('https://media.revistagq.com/photos/61e544a3f0e1a56dae27e9e3/16:9/w_2560%2Cc_limit/features.jpg'); */
    border-radius: 0 0 10px 10px;

}

.Features-information-text {

    display: flex;
    flex-direction: column;
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2;
    padding: 0 30px 30px 60px;
    background-color: rgba(255, 255, 255, 0.51);
    width: 35%;
    /* min-width: 415px; */
}

.Features-information-text h1 {
    font-size: 20px;
    padding: 50px 0;
}

.Features-information-text p {
    width: 60%;
}


.Features-information-buttons {
    display: flex;
    margin: 30px 0;
    align-items: center;
}

.Features-information-buttons button {
    border: none;
    width: 180px;
    background: linear-gradient(257deg, rgba(40, 4, 240, 0.78) 0%, rgba(28, 132, 230, 0.78) 100%);
    /* background: linear-gradient(257deg, rgba(171, 148, 226, 0.78) 0%, rgba(64, 0, 255, 0.75) 100%); */
    font-weight: 300;
    border-radius: 30px;
    color: white;
    padding: 7px;
    font-size: 12px;
}

.Features-information-buttons a {
    width: 180px;
    border: none;
    color: #676767;
    margin: 0 0 0 30px;
    font-size: 12px;

}


.Features-information h1 {
    color: #1f1f1f;
    font-weight: 300;
    font-size: 40px;
    text-align: left;

}

.Features-information{
    span {
    color: rgb(0,28,253)
}
p {
    color: #525252;
    text-align: left;

}
}

.Features-text-selector {
    text-align: center;
     h1 {
    font-weight: 300;
    font-size: 50px;
    margin: 110px 0 90px 0;
}
p {
    width: 50%;
    margin: 0 auto 60px auto;
    color: #707070;
}
}


.Features-card-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 25px;
    grid-row-gap: 15px;
}

.Features-secondary{
    display: grid;
    grid-template-rows: repeat(1, 95%);
    grid-template-columns:3fr 1fr 1fr 1fr;
    grid-row-gap: 15px;
    grid-column-gap: 25px;
}

.Features-secondarytwo {
    display: grid;
    /* grid-template-rows: repeat(1, 1fr); */
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 15px;
    grid-column-gap: 25px;
    margin-top: 15px;
}

/*RESPONSIVE*/

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    .Features-card-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    .Features-card-container {
        grid-template-columns: repeat(1, 1fr);
        /*grid-template-rows: repeat(5 , 1fr);*/

    }

    .Features-secondary {

        grid-template-rows: repeat(1, 9%);
        grid-template-columns: repeat(1, 1fr);
    }

    .Features-secondarytwo {

        grid-template-rows: repeat(4, 1fr);
        grid-template-columns: repeat(1, 1fr);
    }

    .Features-information-text {
        padding: 0 30px 30px 60px;
        min-width: 315px;
    }

    .Features-information-text h1 {
        font-size: 30px;
        padding: 50px 0;
    }

    .Features-information-text p {
        width: 80%;
        font-size: 13px;
    }

    .Features-text-selector h1 {
        font-size: 40px;
    }

    .Features-text-selector p {
        width: 80%;
        margin: 0 auto 60px auto;
    }

}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 450px) {

    .Features-information {

        padding: 40px 0 60px 0;


    }

    .Features-information-text {
        padding: 30px 50px;
        min-width: 0;
        width: 100%;
    }

    .Features-information-text h1 {
        font-size: 30px;
        padding: 50px 0;
    }

    .Features-information-text p {
        width: 100%;
        font-size: 13px;
    }

    .Features-text-selector h1 {

        font-size: 30px;

    }

}