.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    border-bottom: 1px solid rgb(170, 170, 170);
    margin: 0 0 10px;
    padding: 0;
  }

  &__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;

    &--selected {
      background: #fff;
      border-color: #aaa;
      color: black;
      border-radius: 5px 5px 0 0;
      // z-index: 10;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      outline: none;

      &::after {
        content: "";
        position: absolute;
        height: 4px;
        left: 0px;
        right: 0px;
        // bottom: -5px;
        top: -3px;
        // background: #fff;
        border-radius: inherit;
        // z-index: -10;
        background: linear-gradient(257deg, rgba(40, 4, 240, 0.78) 0%, rgba(28, 132, 230, 0.78) 100%);
      }
    }
  }

  &__tab-panel {
    display: none;
   align-items: center;
    &--selected {
      display: block;
      text-align: center;
    }
  }
}
