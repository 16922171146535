.Contactos-container {
  width: 100%;
  position: relative;
  padding-top: 50px;

  span {
    background-image: linear-gradient(
      to right,
      rgba(40, 4, 240, 0.78),
      rgba(28, 132, 230, 0.78) 50%,
      #02246a 50%
    );
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 5px 0;
    position: relative;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
  }

  span:before {
    content: "";
    background: linear-gradient(
      257deg,
      rgba(40, 4, 240, 0.78) 0%,
      rgba(28, 132, 230, 0.78) 100%
    );
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.3s ease-in-out;
  }

  span:hover {
    background-position: 0;
  }

  span:hover::before {
    width: 100%;
  }
}

.Contactos-selector {
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  justify-content: center;
}

.Contactos-text-selector {
  h1 {
    text-align: center;
    font-weight: 900;
    font-size: 40px;
    text-align: center;
    position: relative;
    padding-bottom: 15px;
  }

  h1:after {
    position: absolute;
    content: "";
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 150px;
    height: 3px;
    background: linear-gradient(
      257deg,
      rgba(40, 4, 240, 0.78) 0%,
      rgba(28, 132, 230, 0.78) 100%
    );
    margin: 0 auto;
  }
}

.Contactos-maps-one {
  margin: 2px;
}

.Contactos-maps-two {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 4fr;
}

.ImgWp {
  width: 5%;
}

.ImgUbicacion {
  width: 10%;
}

.ideas {
  padding: 80px 0;
  // background-color: #fff;
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(255, 255, 255, 1) 0%,
    #a3c5f8 100%
  );
  position: relative;
  border-top: 4px solid;
  .titlepage {
    text-align: center;

    span:after {
      position: absolute;
      content: "";
      left: 0px;
      right: 0px;
      bottom: -3px;
      width: 150px;
      height: 3px;
      background: linear-gradient(
        257deg,
        rgba(40, 4, 240, 0.78) 0%,
        rgba(28, 132, 230, 0.78) 100%
      );
      margin: 0 auto;
    }

    p {
      color: #2a2b2b;
      display: block;
      font-size: 17px;
      font-weight: 400;
      line-height: 30px;
    }

    h2 {
      color: #02246a;
      font-weight: 500;
    }
  }
}

.ideas::before {
  position: absolute;
  bottom: 0;
  right: 38px;
  content: "";
  background: url(../Assets/iadius_cross.png);
  background-repeat: no-repeat;
  width: 620px;
  height: 100%;
}

.border_trbl {
  border: #0e0f0f87 solid 1px;
  margin: 50px;
  box-shadow: 9px 0px 30px rgba(57, 56, 56, 0.2);
  background: #fff;
  position: relative;
  width: 400px;
  height: 300px;
  text-align: center;
}

.ideas_box {
  text-align: center;
}

.ideas_box {
  h3 {
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    background: #02246a;
    width: 180px;
    margin: 0 auto;
    border-radius: 121px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: #ffca56 solid 11px;
  }
  p {
    color: #2a2b2b;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    margin-top: 20px;
  }
}

.margin_bottom {
  margin-bottom: 30px;
}
