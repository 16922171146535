
.secondary-navbar {
    width: 100%;
    background-color: #fdfdfd;
    /*background: linear-gradient(257deg, rgba(2, 0, 36, 0) 0%, rgb(220, 220, 220) 100%);*/
    padding: 10px 0;
    border-bottom: 1px solid #d2d2d2;
    position: sticky;
    top: 63px;
    z-index: 5;
    /* display: flex;*/
    /* flex-direction: row;*/
    /*justify-content: center;*/

}

.secondary-navbar-selector {
    width: 90%;
    margin: auto;
    display: flex;
    /*justify-content: space-between;*/
    color: #7a7a7a;
    font-size: 14px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.secondary-navbar-box-2 {
    display: flex;
    align-items: center;
}

.now-button {
    border: none;
    width: 160px;
    height: 25px;
    background: linear-gradient(257deg, rgba(40, 4, 240, 0.78) 0%, rgba(28, 132, 230, 0.78) 100%);
    font-weight: 300;
    border-radius: 30px;
    color: white;
    padding: 2px;
    font-size: 12px;

}

.shop-cart-button {
    border: none;
    background: none;
}

.secondary-navbar-box-2 img {
    width: 30px;
    margin: 0 0 0 30px;
}

.modal-cart-container {
    background: red;
    width: 100%;
    height: 100%;
 position: absolute;
}



/*RESPONSIVE */

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 500px) {
    .now-button {
      display: none;

    }

}
