@import "../../SCSS/base/variables";

.container-footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 30px;
  font-size: 0.8rem;
  line-height: 2;
  color: lightgrey;
  text-transform: uppercase;
  padding-top: 30px;
  background-color: rgb(0, 0, 0);
  border-top: 4px solid #a3c5f8;
  .imgLogo {
    // margin-top: -10px;
    width: 200px;
    margin-left: 50px;
  }

  .imgSegura {
    margin-top: 10px;
  }
}

.separador {
  border-right: solid 1px rgba(85, 81, 81, 0.74);
  margin-left: 40px;
  a{
    color:lightgrey
  }
  a:hover{
    color:$primary-color;
  }
}

.separador-siguenos {
  //   display: inline;
  margin-left: 40px;
  .texto {
    display: inline-block;
    padding-right: 5px;
  }

  .textoDos {
    display: inline-block;
    position: relative;
    top: 6px;
    a{
      color:lightgrey
    }
    a:hover{
      color:$primary-color;
    }
  }
}

@media screen and (max-width: 960px) {
  .container-footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    // background-color:rgb(240,240,240) ;
    // display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100px;
  }
}

.sub-container {
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: rgb(0, 0, 0);
}

.copy {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: solid 1px;
  padding-top: 7px;
  padding-bottom: 7px;
  p {
    color: lightgrey;
  }
  span {
    color: $primary-color;
    margin: 0 0 $space 0;
    font-weight: 700;

    &:last-child {
      margin: 0;
    }
  }
  img {
    position: absolute;
    right: 40px;
    width: 30px;
  }
}

.icon {
  font-size: 1.5rem;
  padding-left: 5px;
  padding-right: 5px;
}
