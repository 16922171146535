.home-selector-none{
  display: none;
}

.home-selector-block{
    display: block;
}

/*.home-selector{*/
/*    overflow: hidden;*/
/*}*/